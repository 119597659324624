
import { Component, Emit, Model, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";

@Component
export default class ChangePasswordForm extends Vue {
  @Model("input", { type: Object }) readonly passwords!: object;

  @Emit()
  input() {
    return this.localPasswords;
  }

  localPasswords!: object;

  valid: boolean = true as boolean;
  errors: object = {} as object;

  rules = {
    required: Rules.required,
    birthdate: Rules.birthdate
  };

  constructor() {
    super();

    this.localPasswords = { ...this.passwords };
  }

  @Watch("localPasswords")
  onLocalPasswordsChanged() {
    this.input();
  }

  @Watch("passwords")
  onPasswordsChanged() {
    if (JSON.stringify(this.passwords) == JSON.stringify(this.localPasswords)) {
      return;
    } else {
      this.localPasswords = { ...this.passwords };
      (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
    }
  }
}
